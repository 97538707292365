/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Chip,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Switch,
  TableCell,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Moment from "moment";
import client from "ApiClient";
import { DialogActions, DialogContent, Grid, IconButton, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConfirmationAlert from "components/ConfirmationAlert";
import Edit from "./edit";
import CustomToolbarSelect from "./customToolbarSelect";
import SubscriberLog from "./SubscriberLog";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [downloadData, setDownloadData] = useState([{}]);
  const [openAlert, setOpenAlert] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedSubscriber, setSelectedSubscriber] = useState(null);
  const [openLog, setOpenLog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [hideDisabled, setHideDisabled] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "subscribers",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `subscribers`;
    options.params = {
      currentPage: page,
      perPage: rowsPerPage,
      search: searchText,
      search_by_tag: searchTag,
      hide: hideDisabled,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setSubscribers(response.subscribers);
        setCount(response.total);
        setPage(response.current_page);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getDownloadData = async (event, done) => {
    setIsLoadingCsv(true);
    options.method = "GET";
    options.url = `subscribers`;
    options.params = {
      currentPage: 0,
      perPage: 0,
      search: "",
      search_by_tag: "",
      hide: hideDisabled,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoadingCsv(false);
        setDownloadData(response.subscribers);
      })
      .catch((error) => {
        setIsLoadingCsv(false);
        console.log(error);
      });
  };

  const getTags = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `subscribers/tagCounts`;
    options.params = { hide: hideDisabled };
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setTags(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getTags();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog) {
      getData();
    }
  }, [openLog]);

  useEffect(() => {
    getData();
    // getTags();
  }, [hideDisabled]);

  useEffect(() => {
    getTags();
    getData();
  }, []);

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `subscribers`;
    options.data = JSON.stringify(selectedSubscriber);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        getTags();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const unsubscribe = () => {
    setIsLoading(true);
    const options = {
      method: "PUT",
      url: `subscribers/unsubscribe`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify(selectedSubscriber);

    client
      .request(options)
      .then((response) => {
        getData();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return (
          <Tooltip title={"Active"}>
            <Chip fontSize="small" style={{ backgroundColor: "#bce2be" }} label="Active" />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title={"Unsubscribed"}>
            <Chip fontSize="small" style={{ backgroundColor: "#FF5D59" }} label="Unsubscribed" />
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip title={"Complained"}>
            <Chip fontSize="small" style={{ backgroundColor: "#FF5D59" }} label="Complained" />
          </Tooltip>
        );
      case 3:
        return (
          <Tooltip title={"Bounced"}>
            <Chip fontSize="small" style={{ backgroundColor: "#FF5D59" }} label="Bounced" />
          </Tooltip>
        );
      case 8:
        return (
          <Tooltip title={"Not engaging"}>
            <Chip fontSize="small" style={{ backgroundColor: "#E3E3E3" }} label="Not engaging" />
          </Tooltip>
        );

      case 9:
        return (
          <Tooltip title={"Paused"}>
            <Chip fontSize="small" style={{ backgroundColor: "#FAC87E" }} label="Paused" />
          </Tooltip>
        );
      case 12:
        return (
          <Tooltip title={"Pending"}>
            <Chip fontSize="small" style={{ backgroundColor: "#FAC87E" }} label="Pending" />
          </Tooltip>
        );
    }
  };

  const columns = [
    {
      label: " ",
      name: "status",
      options: {
        filter: true,
        customBodyRender: (value) => getStatus(value),
      },
    },
    {
      label: "First",
      name: "first",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Last",
      name: "last",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Tags",
      name: "tags",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Chip label={value.split(",").length} />
            </Tooltip>
          );
        },
      },
    },
    {
      label: " ",
      name: "id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedSubscriber(subscribers[dataIndex]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelectedSubscriber(subscribers[dataIndex]);
                  setOpenLog(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip title="View details">
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const dataFromUsers = () => {
    return downloadData;
  };

  const customToolbarSelect = () => (
    <CustomToolbarSelect onDelete={() => setOpenAlert(true)} onUnsubscribe={() => unsubscribe()} />
  );

  const CustomToolbar = ({ displayData }) => {
    return (
      <>
        <CSVLink
          filename={"subscribers.csv"}
          data={dataFromUsers()}
          className="btn"
          style={{
            backgroundColor: "#fff",
            color: "#666",
            fontSize: "24px",
            fontWeight: 500,
            paddingTop: "10px",
          }}
        >
          <Tooltip title="Download user list" placement="top">
            <CloudDownloadIcon />
          </Tooltip>
        </CSVLink>
        <Tooltip title="Hide the unsubscribed" placement="top">
          <FormControlLabel
            value="start"
            labelPlacement="start"
            control={
              <Switch checked={hideDisabled} onChange={() => setHideDisabled(!hideDisabled)} />
            }
            label={
              <MDTypography variant="h5" fontWeight="medium">
                Hide inactive
              </MDTypography>
            }
          />
        </Tooltip>
      </>
    );
  };

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any subscribers",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "subscriber/s selected",
      },
    },
    serverSide: true,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    download: false,
    downloadOptions: { filename: "subscribers.csv", separator: "," },
    customToolbarSelect: customToolbarSelect,
    customToolbar: CustomToolbar,
    serverSide: true,
    //count, // Use total number of items
    count: count, // Unknown number of items
    page: page,
    searchText: searchText,
    /*
    searchProps: {
      onBlur: (e) => {
        // console.log("onBlur!",e);
        // setSearchText(searchQuery);
      },
      onKeyUp: (e) => {
        // console.log("onKeyUp!",e);
        // setSearchText(searchQuery);
      },
    },
    */
    searchPlaceholder: "Search by name,email or tags",
    /*
    customSearch: (searchQuery, currentRow, columns) => {
     
      let isFound = false;
      currentRow.forEach((col) => {
        if (col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }
      });
      return isFound;
     
    },
    */
    isRowSelectable: (dataIndex) => {
      return subscribers[dataIndex].status !== 1;
    },
    onRowClick: (rowData, index) => {
      //  setSerId(series[index.dataIndex].ser_id);
      //  setSerName(series[index.dataIndex].ser_name);
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let selected = [];

      // emailsSelected.push(acconuntsData[curRowSelected[0].dataIndex]);

      allRowsSelected.map((row) => {
        selected.push(subscribers[row.dataIndex]);
      });

      setSelectedSubscriber(selected);
    },
    onTableChange: (action, tableState) => {
      // console.log("action", action);
      // console.log("tableState", tableState);
      setRowsPerPage(tableState.rowsPerPage);

      if (action === "changePage") {
        //  console.log("Go to page", tableState.page);
        setPage(tableState.page);
        // this.changePage(page);
      }
      if (action === "changeRowsPerPage") {
        //  console.log("Change rows per page", rowsPerPage);
        setRowsPerPage(tableState.rowsPerPage);
        // this.changeRowsPerPage(rowsPerPage);
      }
      if (action === "search") {
        //  console.log("Change rows per page", rowsPerPage);
        setSearchText(tableState.searchText);
        // this.changeRowsPerPage(rowsPerPage);
      }
    },
  };

  const tagsColumns = [
    {
      label: "Tag",
      name: "tag",
      options: {
        filter: true,
      },
    },
    {
      label: "Count",
      name: "count",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
  ];

  const tagsTableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any tag",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "tag/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    delete: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "tags.csv", separator: "," },
    customToolbarSelect: (selectedRows) => {},
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      setPage(0);
      if (rowsSelected.length === 0) {
        setSearchTag("");
        return;
      }
      setSearchTag(
        tags[rowsSelected[0]].tag.indexOf("<All tags combined>") > -1
          ? ""
          : tags[rowsSelected[0]].tag
      );
    },
  };

  useEffect(() => {
    getDownloadData();
    getData();
  }, [rowsPerPage, page, searchText, searchTag]);

  return (
    <div>
      <Dialog open={openDetails} onClose={() => setOpenDetails(false)}>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          {selectedSubscriber && (
            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
              Subscriber Details for {selectedSubscriber.sub_first} {selectedSubscriber.sub_last}
            </MDTypography>
          )}
        </MDBox>
        <DialogContent></DialogContent>
        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton onClick={() => setOpenDetails(false)} variant="outlined" color="secondary">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this subscriber?"
      />
      <SubscriberLog
        openModal={openLog}
        onClose={() => setOpenLog(false)}
        subscriber={selectedSubscriber}
      />
      <Edit
        openModal={openEdit}
        onClose={() => {
          setSelectedSubscriber(null);
          setOpenEdit(false);
        }}
        onDone={() => {
          getData();
          setSelectedSubscriber(null);
        }}
        subId={selectedSubscriber == null ? 0 : selectedSubscriber.id}
      />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <MUIDataTable
            title={
              isLoading ? (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              ) : (
                "Subscribers"
              )
            }
            data={subscribers}
            columns={columns}
            options={tableOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <MUIDataTable
            title={"Tags"}
            data={tags}
            columns={tagsColumns}
            options={tagsTableOptions}
          />
        </Grid>
      </Grid>
    </div>
  );
}
